import { Route, Switch, Redirect } from 'react-router-dom'

// import Layout from './components/layout/Layout'
import { lazy } from 'react'
import { Suspense } from 'react'
import LoadingSpinner from './components/UI/LoadingSpinner'

const NewQuote = lazy(() => import('./pages/NewQuote'))
const QuoteDetail = lazy(() => import('./pages/QuoteDetail'))
const NotFound = lazy(() => import('./pages/NotFound'))
const AllQuotes = lazy(() => import('./pages/AllQuotes'))
const Layout = lazy(() => import('./components/layout/Layout'))

function App() {
  return (
    <Suspense
      fallback={
        <div className="centered">
          <LoadingSpinner />
        </div>
      }
    >
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/quotes" />
          </Route>
          <Route path="/quotes" exact>
            <AllQuotes />
          </Route>
          <Route path="/quotes/:quoteId">
            <QuoteDetail />
          </Route>
          <Route path="/new-quote">
            <NewQuote />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    </Suspense>
  )
}

export default App
